//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapMutations } from "vuex";
export default {
  methods: {
    toRegisterone() {
      this.$router.push({ path: "/homes/register", query: { type: "1" } });
    },
    toRegistertwo() {
      this.$router.push({ path: "/homes/register", query: { type: "2" } });
    },
  },
};
